import * as React from 'react'
import PropTypes from 'prop-types'

import BlogCard from './card'

const BlogList = ({ order }) => {
	return (
		<div className="grid grid-cols-1 lg:w-10/12 gap-y-80 sm:gap-y-100 lg:gap-y-120 xl:gap-y-160 xxl:gap-y-180 mx-auto">
			{order.map(blog => (
				<BlogCard key={`blog_card_${blog.id}`} blog={blog} />
			))}
		</div>
	)
}

BlogList.propTypes = {
	order: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
		})
	).isRequired,
}

export default BlogList
