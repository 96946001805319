import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/layout'

import BlogList from '../components/blog/list'

const BlogPage = ({ data }) => {
	const blog = data.strapi.blogPage
	const list = data.strapi.blogs

	return (
		<Layout page={blog} metadata={blog.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Blog Cards */}
				<section className="py-40 sm:py-60 md:py-80 lg:py-100 xl:py-120">
					<BlogList order={list} />
				</section>
			</div>
		</Layout>
	)
}

BlogPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			blogs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
			blogPage: PropTypes.shape({
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}

export const query = graphql`
	{
		strapi {
			blogs(sort: "date:desc") {
				id
				slug
				author
				date
				title
				thumbnail {
					url
					ext
					height
					width
					name
					imageFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								width: 1075
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
				}
			}
			blogPage {
				footer_headline
				footer_link_url
				footer_link_text
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
		}
	}
`

export default BlogPage
