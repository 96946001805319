import * as React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import Image from '../partials/image'

const BlogCard = ({ blog }) => {
	return (
		<Link className="group" to={`/blog/${blog.slug}`}>
			{/* Meta Section */}
			<h3 className="text-grey">
				{blog.author} / {blog.date}
			</h3>

			{/* Title Section */}
			<h2 className="h1 w-10/12 pb-30 lg:pb-60 group-hover:text-blue group-hover:underline">
				{blog.title}
			</h2>

			{/* Image Section */}
			<Image asset={blog.thumbnail} />
		</Link>
	)
}

BlogCard.propTypes = {
	blog: PropTypes.shape({
		slug: PropTypes.string.isRequired,
		author: PropTypes.string.isRequired,
		date: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		thumbnail: PropTypes.shape({}).isRequired,
	}).isRequired,
}

export default BlogCard
